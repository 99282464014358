(()=>{

	// $(window).load(function(){
	// 	$('html,body').animate({ scrollTop: 0 }, '1');
	// });

	const $doc = $(document);
	const $win = $(window);
	let docHeight = 0;
	let winHeight = 0;
	let vh = 0;



	const pageScrollTo = (tar, e) => {

		if(e)
		e.preventDefault();

		tar = tar.replace('#', '');

		if($('[data-id="' + tar + '"]').length){
			let diff = $('.toggle-nav-button').is(':visible') ? 60 : 144;
			let pos = tar == '#header' ? 0 : $('[data-id="'+tar+'"]').offset().top - diff;
			$('html,body').stop().animate({ scrollTop: pos }, 1000, 'easeOutExpo');
		}

	}

	const resizeHandler = ()=>{
		
		docHeight = $doc.height();
		winHeight = $win.height();

		vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);		

	}

	$('.hero-section .hero-image *').addClass('executed');


	$('.anchor').off('click').on('click', (e)=>{
		e.preventDefault();
		let tgt = $(e.currentTarget).attr('href').replace('#', '');
		pageScrollTo(tgt, e)
	});
	
	$('.box-header').matchHeight();
	$('.box-content').matchHeight();

	resizeHandler();

	$win.on('load resize', resizeHandler);

	$('.toggle-nav-button').on('click', ()=>{
		menu.toggle();
	});

	$('.hamburger-button').on("click", function() {
		$('.site-nav').slideToggle();
		$('.site-nav').toggleClass("open");
		$(this).toggleClass("opened");
	});

	$('.toggle-bar').on("click", function() {
		$(this).next().slideToggle();
		$(this).toggleClass("open");
	});

	$('.site-nav a').on('click', (e)=>{
		if(menu.menuOpen){
			menu.close();
		}
	});		

	$('.hero-section .fadein').addClass('executed');

		let st = 0;

		$win.on('scroll.home', function (){
			st = $win.scrollTop();	

			//$('.hero-section').css({transform: 'translateY(-' + Math.round(st * speed) + 'px)'});
			// if(st > 100){
			// 	$('.scroll-icon').addClass('hidden');
			// } else {
			// 	$('.scroll-icon').removeClass('hidden');
			// }

			//let n = 0;
			$('.prlx').each((i, elem) => {
				let thisY = $(elem).offset().top - ($(elem).height() / 2);
				let ratio =  0.8;

				if(thisY <= (st + (winHeight*ratio))){

					$(elem).addClass('executed');

				}
			
			});


			if(st >= docHeight - winHeight + 100){
				$('.prlx').addClass('executed');
			}

		});

		$win.trigger('scroll.home');

		setTimeout(()=>{
			$('body').removeClass('no-scroll');
		}, 2000);

		if($('.thumbnail-list li').length > 1){
			const slider = new Swiper('.swiper-container', {
				effect: 'fade',
				fadeEffect: {
					crossFade: true
				}
			});
			
			$('.thumbnail-list li button').on('click', (e)=>{
				const index = $(e.currentTarget).parent().index();
				slider.slideTo(index);
			});
		}


})();




const menu = {

	menuOpen: false,
	timer: null,

	open(){

		this.menuOpen = true;

		$('.site-nav').show();

		if(this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(
			()=>{				
				$('.site-nav').addClass('visible');
				$('.toggle-nav-button').addClass('opened');
			}, 100);	

	},

	close(){

		this.menuOpen = false;
		
		$('.site-nav').removeClass('visible');
		$('.toggle-nav-button').removeClass('opened');
		
		if(this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(
			()=>{
				$('.site-nav').hide();
			}, 300);
		
	},

	toggle(){
		if(this.menuOpen){
			this.close();
		} else {
			this.open();
		}
	}	

}

